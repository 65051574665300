import React, { useContext } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import moment from 'moment-timezone'
import { Grid, Header, Icon, Button, List } from 'semantic-ui-react'
import DataContext from '../contexts/DataContext'
import { ScheduleProvider } from '../contexts/ScheduleContext'
import { LOAD_SINGLE_MEETING_QUERY } from '../graphql/schedule'
import Loading from '../components/Loading'
import ReportNoShow from '../components/Modals/ReportNoShow'
import MeetingInvite from '../components/MeetingInvite'
import RescheduleMeeting from '../components/Modals/RescheduleMeeting'
import AddToCalendarContext, {
  AddToCalendarProvider,
} from '../contexts/AddToCalendarContext'

const Meeting = () => {
  const { advisor } = useContext(DataContext)

  const { id } = useParams()
  const history = useHistory()
  const { loading, error, data } = useQuery(LOAD_SINGLE_MEETING_QUERY, {
    variables: { id },
  })

  if (loading) return <Loading />
  if (error) {
    return (
      <Grid container id="error" textAlign="center">
        <Grid.Row>
          <Header>
            <Icon name="ban" />
            Page Not Found
          </Header>
        </Grid.Row>
        <Grid.Row>
          Oops! The page you are looking for does not exist. It might have been
          moved or deleted.
        </Grid.Row>
        <Grid.Row>
          <Button
            className="primary-btn"
            primary
            onClick={() => history.push('/dashboard')}
          >
            Back to Home
          </Button>
        </Grid.Row>
      </Grid>
    )
  }
  const { job } = data
  const meetingTime = moment.tz(job.meetingAt, advisor.timeZone)
  const disableModification = job.overall_status !== 'Scheduled'
  const disableNoShowReport =
    job.overall_status !== 'Scheduled' || moment().isBefore(job.meetingAt)
  const NoShowText = (
    <div
      id="report-no-show"
      className={disableNoShowReport ? 'disable-no-show' : null}
    >
      <Icon name="flag" />
      Report No-show
    </div>
  )

  return (
    <Grid container id="meeting">
      <Grid.Row>You have a new event</Grid.Row>
      <Grid.Row>
        <Header>{job.project.title}</Header>
      </Grid.Row>

      <Grid.Row>
        <div
          className={`grid-container ${
            !job.project.preInterviewLink && 'no-material'
          }`}
        >
          <div className="meeting-datetime">
            <List size="large">
              {job.meetingAt ? (
                <List.Item>
                  <List.Header>
                    {meetingTime.format('dddd, MMMM DD, YYYY')}
                  </List.Header>
                  <List.Header>
                    {`${meetingTime.format('hh:mm a')} - 
                    ${meetingTime
                      .add(1, 'hour')
                      .format('hh:mm a')} ${meetingTime.format('z')}`}
                  </List.Header>
                </List.Item>
              ) : (
                <List.Item>
                  <List.Header>No Meeting Time</List.Header>
                </List.Item>
              )}
            </List>
          </div>
          {job.project.preInterviewLink && (
            <div className="pre-interview-material">
              <List>
                <List.Item>
                  <List.Header>Pre-interview Material</List.Header>
                  <List.Description id="pre-interview-link">
                    To help guide the discussion, the interviewer has provided
                    some material for your review before the meeting.
                    <p>
                      To access the material, please follow this link:{'  '}
                      <a
                        href={job.project.preInterviewLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Pre-interview Material
                      </a>
                    </p>
                  </List.Description>
                </List.Item>
              </List>
            </div>
          )}
          <div className="project-description">
            <List>
              <List.Item>
                <List.Header>Project Description</List.Header>
                <List.Description id="description">
                  {job.project.description}
                </List.Description>
              </List.Item>
            </List>
          </div>
          <div className="meeting-invite">
            <List id="meeting-invite-list">
              <MeetingInvite
                meetingInvite={job.zoomMeeting.meetingInvite}
                meetingLink={job.zoomMeeting.meetingLink}
              />
            </List>
          </div>
          <div className="action-btns">
            <List>
              <List.Item>
                <AddToCalendarProvider>
                  <AddToCalendarContext.Consumer>
                    {({ handleAddToCalendar }) => (
                      <Button
                        primary
                        disabled={!job.meetingAt}
                        onClick={() => handleAddToCalendar(job)}
                      >
                        <Icon name="calendar plus" />
                        Add to Calendar
                      </Button>
                    )}
                  </AddToCalendarContext.Consumer>
                </AddToCalendarProvider>
              </List.Item>
              <List.Item>
                <ScheduleProvider>
                  <RescheduleMeeting job={job}>
                    <Button
                      className="basic-btn"
                      disabled={disableModification}
                    >
                      <Icon name="refresh" />
                      Reschedule
                    </Button>
                  </RescheduleMeeting>
                </ScheduleProvider>
              </List.Item>
            </List>
          </div>
          <div className="secondary-action-btns">
            {disableNoShowReport ? (
              NoShowText
            ) : (
              <ReportNoShow job={job}>{NoShowText}</ReportNoShow>
            )}

            <Link to="/projects/active" id="view-upcoming-events">
              <Icon name="list" />
              View all upcoming events
            </Link>
          </div>
        </div>
      </Grid.Row>
    </Grid>
  )
}

export default Meeting
