import React from 'react'
import { useQuery } from '@apollo/client'
import { List, Header } from 'semantic-ui-react'
import { SINGLE_PROJECT_PUBLIC_QUERY } from '../../graphql/project'

const PublicProjectInfo = ({ project, qualificationResponses }) => {
  // Fetching the project query to update the offerRate because it's required to be changed dynamically
  // the "project" from props is used from SessionStorage which data would always be the same
  const { data } = useQuery(SINGLE_PROJECT_PUBLIC_QUERY, {
    variables: { id: project.id },
  })
  return (
    <div id="project-fields">
      <List>
        <List.Item>
          <List.Header>Type:</List.Header>
          <List.Description>
            {project.type === 'Interview'
              ? 'Zoom meeting (45-60 minutes)'
              : 'Online survey'}
          </List.Description>
        </List.Item>
        <List.Item>
          <List.Header>Honorarium Rate:</List.Header>
          <List.Description>
            ${data?.project.offerRate}
            {project.type === 'Interview'
              ? ' per hour (pro-rated)'
              : ' for submission of a complete survey response'}
          </List.Description>
        </List.Item>
        <List.Item>
          <List.Header>Project Description:</List.Header>
          <List.Description>
            <div className="project-description">{project.description}</div>
          </List.Description>
        </List.Item>
        {qualificationResponses && qualificationResponses.length > 0 && (
          <List.Item>
            <List.Header>Qualifications: </List.Header>
            <div id="qualification-statement">
              Your answers to the below question(s)
            </div>
            {qualificationResponses.map((q) => (
              <div key={q.question} className="qualification-responses-landing">
                <List.Description>
                  <Header size="tiny">{q.question}</Header>
                  {q.response.map((res) => (
                    <div id="qualification-elaboration" key={res}>
                      - {res}
                    </div>
                  ))}
                </List.Description>
              </div>
            ))}
          </List.Item>
        )}
      </List>
    </div>
  )
}

export default PublicProjectInfo
